import React, {Component} from "react";
import logo from "./logo.svg";
import "./App.css";
import QrReader from "react-qr-reader";
import QRCode from "qrcode.react";
import uuid from "uuid-random";
import ClipboardJS from "clipboard";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as firebase from "firebase/app";
import {getAuth, signInAnonymously, onAuthStateChanged} from "firebase/auth";
import {getDatabase, update, ref, onValue, set} from "firebase/database";

var config = {
  apiKey: "AIzaSyAE2EVjSmp5HCWUwohs_RxOEvecfVseU7M",
  authDomain: "quickopy-d0359.firebaseapp.com",
  databaseURL: "https://quickopy-d0359.firebaseio.com",
  projectId: "quickopy-d0359",
  storageBucket: "quickopy-d0359.appspot.com",
  messagingSenderId: "918209016504",
};
firebase.initializeApp(config);

var clipboard = null;

class Header extends Component {
  reset = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    window.location.reload();
  };

  render() {
    return (
      <header>
        <nav className="navbar navbar-dark bg-dark">
          <a className="navbar-brand" href="https://quickopy.com">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top App-logo"
              alt="logo"
            />
            Quickopy
          </a>
          <div className="navbar-actions">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.reset}
            >
              Regenerate
            </button>
          </div>
        </nav>
      </header>
    );
  }
}

class App extends Component {
  state = {
    // Webapp fields
    id: null,
    name: null,
    messageOut: "",
    remoteDeviceId: null,
    connected: false,
    readingQr: false,
    loading: true,
    showAlert: false,
    // Local device ref
    localDevice: null,
    // Remote device ref
    remoteDevice: null,
  };

  getRandom = (items) => items[Math.floor(Math.random() * items.length)];

  constructor(props) {
    super(props);

    // Start firebase session
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser !== null) currentUser.reload();
    signInAnonymously(auth).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
    });
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;

        // User session created. Logged in!
        this.initDevice(); // TODO Fix this race condition
      } else {
        // User is signed out.
        // ...
      }
    });

    // Generate random device info
    if (localStorage.getItem("id") === null) {
      const id = uuid();
      localStorage.setItem("id", id);
    }
    if (localStorage.getItem("name") === null) {
      const colors = [
        "Red",
        "Blue",
        "Yellow",
        "Green",
        "Pink",
        "Grey",
        "Black",
        "White",
        "Orange",
        "Brown",
        "Purple",
      ];
      const modifiers = ["Light", "Dark", ""];
      const animals = [
        "Fox",
        "Turtle",
        "Bird",
        "Dog",
        "Cat",
        "Mouse",
        "Elephant",
        "Unicorn",
        "Donkey",
        "Monkey",
        "Whale",
        "Bull",
        "Cheetah",
        "Lion",
        "Tiger",
        "Panther",
        "Dinosaur",
        "Snake",
      ];
      const name =
        this.getRandom(modifiers) +
        " " +
        this.getRandom(colors) +
        " " +
        this.getRandom(animals);
      localStorage.setItem("name", name);
    }

    this.state.id = localStorage.getItem("id");
    this.state.name = localStorage.getItem("name");
    this.state.showAlert = localStorage.getItem("seenAlertVersion") !== 1;
  }

  initDevice = () => {
    const db = getDatabase();
    var device = ref(db, "devices/" + this.state.id);
    onValue(device, (snapshot) => {
      if (!snapshot.exists()) {
        set(ref(db, "devices/" + this.state.id), {
          id: this.state.id,
          name: this.state.name,
          remoteDeviceId: null,
          inbox: null,
        });
      } else {
        const data = snapshot.val();
        if (
          data &&
          this.state.localDevice &&
          this.state.localDevice.inbox !== "" &&
          this.state.localDevice.inbox !== data.inbox
        ) {
          console.log("Ding!");
          document.getElementById("inbox").classList.add("animated", "zoomIn");
          setTimeout(() => {
            document
              .getElementById("inbox")
              .classList.remove("animated", "zoomIn");
          }, 1000);
        }
        this.setState({ ...this.state, localDevice: data });

        // Connect to remote device
        if (!!data.remoteDeviceId) {
          this.connect(data.remoteDeviceId);
        } else {
          this.setState({ ...this.state, loading: false });
        }
      }
    });
  };

  connect = (remoteDeviceId) => {
    // TODO Check remoteDevice exists
    const db = getDatabase();

    // Create connection
    var updates = {};
    // TODO Confirm connection first
    updates["/devices/" + this.state.id + "/remoteDeviceId"] = remoteDeviceId;
    updates["/devices/" + remoteDeviceId + "/remoteDeviceId"] = this.state.id;
    update(ref(db), updates).then(() => {
      // Start tracking remote device info
      var remoteDevice = ref(db, "devices/" + remoteDeviceId);
      onValue(remoteDevice, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.setState(
            {
              ...this.state,
              connected: true,
              loading: false,
              remoteDevice: data,
            },
            this.attachClipboard
          );
        } else {
          // Remote device does not exist yet... O.o
        }
      });
    });
  };

  attachClipboard = () => {
    if (clipboard != null) {
      clipboard.destroy();
    }
    clipboard = new ClipboardJS("#cpy-btn");
    clipboard.on("success", (e) => {
      toast.success("🚀 Copied to clipboard!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      // console.info('Action:', e.action);
      // console.info('Text:', e.text);
      // console.info('Trigger:', e.trigger);
      e.clearSelection();
    });
  };

  changeName = (e) => {
    const name = e.target.value;
    this.setState({ ...this.state, name: name });
    localStorage.setItem("name", this.state.name);

    // TODO Fix slow name change by moving update to bg
    // TODO Fix this ugly temporary hack vvv
    setTimeout(() => {
      const db = getDatabase();
      var updates = {};
      updates["/devices/" + this.state.id + "/name"] = name;
      update(ref(db), updates).then(() => {});
    }, 1);
  };

  changeMessageOut = (e) => {
    this.setState({ ...this.state, messageOut: e.target.value });
  };

  send = () => {
    const db = getDatabase();
    var updates = {};
    updates["/devices/" + this.state.remoteDevice.id + "/inbox"] =
      this.state.messageOut;
    update(ref(db), updates).then(() => {});
    this.setState({ ...this.state, messageOut: "" });
  };

  handleScan = (data) => {
    // TODO Check if data is a valid URL
    if (data) {
      const url = new URL(data);
      const urlParams = new URLSearchParams(url.search);
      // TODO Check if code parameter exists
      this.connect(urlParams.get("code"));
      // TODO Handle connection error
    }
  };

  handleError = (data) => {
    console.log("[QrReader] Oops:" + data);
  };

  toggleQrReading = () => {
    this.setState({ ...this.state, readingQr: !this.state.readingQr });
  };

  render = () => {
    return (
      <div className="App">
        <Header/>
        <main role="main">
          <div className="container">
            {this.state.showAlert ? (
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">
                  Hey{" "}
                  <span role="img" aria-label="waving hand">
                    👋
                  </span>
                  !
                </h4>
                <p>
                  So I have been working on this for a couple of weeks on my
                  free time for my{" "}
                  <a href="https://aaron.com.es/blog/the-lunar-challenge-1-quickopy/">
                    Lunar Challenge
                  </a>
                  . I hope you like it!
                </p>
                <hr/>
                <p className="mb-0">
                  Please your feedback (good or bad) is everything to me. Let me
                  know <a href="mailto:yo@aaron.com.es">by email</a> or{" "}
                  <a href="https://twitter.com/aaronfc">twitter</a>.
                </p>
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Your name
                  </span>
                </div>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.changeName}
                  className="form-control"
                  id="friendly-name"
                />
              </div>
            </div>
            {this.state.loading ? (
              <div className="loading-spinner">
                <i className="material-icons spin">cached</i>
              </div>
            ) : this.state.connected ? (
              <div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Connected to
                    </span>
                  </div>
                  <input
                    type="text"
                    value={this.state.remoteDevice.name}
                    className="form-control"
                    readOnly
                  />
                </div>
                <div className="box">
                  <div className="title">Inbox</div>
                  <div id="inbox" className="input-group">
                    <textarea
                      id="message-in"
                      value={this.state.localDevice.inbox}
                      className="form-control custom-control"
                      rows="3"
                      placeholder="Nothing yet..."
                      readOnly
                    ></textarea>
                    <button
                      id="cpy-btn"
                      type="button"
                      data-clipboard-target="#message-in"
                      className="input-group-addon btn btn-primary"
                    >
                      <i className="material-icons">file_copy</i>
                    </button>
                  </div>
                </div>
                <div className="box">
                  <div className="title">Outbox</div>
                  <div id="outbox" className="input-group">
                    <textarea
                      id="message-out"
                      onChange={this.changeMessageOut}
                      value={this.state.messageOut}
                      className="form-control custom-control"
                      placeholder="Paste to send ..."
                      rows="3"
                    ></textarea>
                    <button
                      type="button"
                      onClick={this.send}
                      className="input-group-addon btn btn-primary"
                    >
                      <i className="material-icons">send</i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.state.readingQr ? (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div align="center">
                          <QrReader
                            delay={100}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div align="center">
                          <button
                            id="qr-button"
                            className="btn btn-primary"
                            onClick={this.toggleQrReading}
                          >
                            Cancel <i className="material-icons">arrow_back</i>
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="jumbotron jumbotron-fluid">
                          <div className="container">
                            <h1 className="display-5">Shoot!</h1>
                            <p className="lead">
                              Aim your camera to{" "}
                              <strong>the other device QR</strong>. Then you
                              will be automatically linked and{" "}
                              <strong>ready to copy/paste</strong>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-sm-9">
                        <div className="jumbotron jumbotron-fluid">
                          <div className="container">
                            <h1 className="display-5">
                              Quick copy&amp;paste between devices
                            </h1>
                            <p className="lead">
                              Just{" "}
                              <strong>open Quickopy on both devices</strong>,
                              link them using <strong>your camera</strong> and
                              you are <strong>ready to copy/paste</strong> from
                              one device to another.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3">
                        <div align="center">
                          <QRCode
                            className="qrcode"
                            value={
                              "https://quickopy.com/?code=" + this.state.id
                            }
                            fgColor="#333"
                            bgColor="#ddd"
                            size="1080"
                          />
                        </div>
                        <div align="center">
                          <button
                            id="scan-button"
                            className="btn btn-primary"
                            onClick={this.toggleQrReading}
                          >
                            Scan <i className="material-icons">photo_camera</i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
        <footer>
          <div>
            Feedback? Contact me by{" "}
            <a href="https://twitter.com/aaronfc">twitter</a> or{" "}
            <a href="mailto:yo@aaron.com.es">email</a>.
          </div>
          <div>
            Thanks to: <a href="https://twitter.com/jmgaya90">@jmgaya90</a>,{" "}
            <a href="https://twitter.com/imborge">@borge</a> and{" "}
            <a href="https://twitter.com/polypiel">@polypiel</a>.
          </div>
        </footer>
        <ToastContainer/>
      </div>
    );
  };
}

export default App;
